@import '../../../../scss/variables';

.main-menu {
    display: block;
    width: auto;
    height: auto;
    background: none;
    cursor: auto;
    float: right;
    margin-right: 5%;

    &-icon {
        margin-right: 7px;
    }

    @media (max-width: $tablet-wide) {
        display: none;
    }
}

.main-menu-link {
    display: inline-block;
    height: auto;
    line-height: normal;
    background: transparent;
    padding: .6em 5px;
    border-top: none;
    color: $main-text-color;
    font-size: 16px;
    text-transform: uppercase;

    @media (max-width: $desktop) {
        padding: .6em 3px;
        font-size: 15px;
    }

    &:hover,
    &.active {
        color: $link-hover-gray;
        text-decoration: none;
    }

    &:focus {
        text-decoration: none;
    }

    &-list {
        position: static;
        width: auto;
        line-height: 70px;
        transform: none;
    }

    &-list-item {
        display: inline-block;
        margin-left: 1em;
    }
}

.main-scrollspy-hover:not(:first-child) a {
    color: $link-hover-gray;
    text-decoration: none;
}
