$phone-sm:      360px;
$phone:         576px;
$tablet:        768px;
$tablet-wide:   859px;
$desktop:       992px;
$desktop-wide:  1200px;

$main-text-color: #eee;

$link-hover-gray: #999;

$link-hover-animation: all 0.3s linear;

$main-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);