@import '../../../scss/variables';

.rhap_container {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: transparent;
    color: #fff;
    min-height: 30px;
    width: 100%;
    margin: -4px auto 0;
}

.rhap_header {
    width: 210px;
    margin-right: 40px;
    margin-bottom: 0;
    font-size: 15px;

    @media (max-width: $phone) {
        margin-right: 10px;
        font-size: 13px;
    }
}

.rhap_current-time {
    color: #fff;
    margin-right: 15px;
}

.rhap_total-time,
.rhap_volume-bar-area,
.rhap_additional-controls {
    display: none;
}

.rhap_progress-section {
    width: 20%;
    margin-right: 20px;

    @media (max-width: $phone) {
        display: none;
    }
}

.rhap_main-controls {
    margin-right: 20px;
}

.rhap_controls-section {
    margin-top: -2px;
    justify-content: flex-start;
    max-width: 150px;
}

.rhap_button-clear {
    color: #fff;
}

.rhap_volume-controls {
    justify-content: flex-start;
    flex: 0;
}

.rhap_main-controls-button {
    font-size: 25px;
    width: 25px;
    height: 25px;
}

.rhap_volume-button {
    font-size: 22px;
    width: 22px;
    height: 22px;
}

.rhap_progress-indicator {
    width: 15px;
    height: 15px;
    margin-top: 3px;
    background: #fff;
}
