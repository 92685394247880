@import "variables";

@font-face {
    font-family: 'abite';
    src: url('../fonts/abite.woff') format('woff');
}

@font-face {
    font-family: 'candara';
    src: url('../fonts/candara.woff') format('woff');
}

body, html, .main-view {
    /* important */
    height: 100%;
    margin: 0;
}

body {
    font-family: "candara", serif;
}

a {
    color: $main-text-color;
    transition: $link-hover-animation;

    &:hover,
    &:active {
        color: $link-hover-gray;
        text-decoration: none;
    }

    &:focus {
        color: $main-text-color;
        text-decoration: none;
    }
}

.btn {
    padding: 8px 12px 6px;

    &:active {
        color: $link-hover-gray;
        text-decoration: none;
        outline: none;
    }

    &:focus {
        color: $main-text-color;
        text-decoration: none;
        outline: none;
    }
}

ul,
li {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.btn-main {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
    background: rgba(255, 255, 255, 0.3);
    color: #fff;
    transition: $link-hover-animation;

    &:hover {
        background: rgba(255, 255, 255, 0.5);
        color: #333;

        .btn-main-link,
        .toggle-shows {
            color: #000;
        }
    }
}

.section-header-icon {
    padding-right: 10px;
}