@import '../../../scss/variables';
@import '../../../scss/mixins';

.shows-header {
    font-size: 16px;
    color: #ccc;
    padding-top: 0 !important;

    @media (max-width: $tablet) {
        display: none;
    }
}

.shows-list {
    padding-top: 20px;
    padding-bottom: 25px;
    text-align: left;

    &:first-child {
        margin-bottom: 20px;
    }

    @media (max-width: $tablet) {
        text-align: center;
    }

    .shows-list-item {
        line-height: 24px;
        width: 50%;
        float: left;

        @media (max-width: $tablet) {
            float: none;
            width: 100%;
            line-height: 28px;
        }

        &:first-child {
            padding-right: 0;
            width: 35%;

            @media (max-width: $tablet) {
                width: 100%;
            }
        }

        &:last-child {
            padding-right: 0;
            width: auto;
        }
    }
}

.shows-large {
    font-size: 26px;
}

.shows-small {
    font-size: 16px;
    font-style: italic;
}

.shows-list-empty {
    font-size: 26px;
    margin: 40px 0 20px;
}

.shows-past-container {
    margin-top: 20px;
    transition: all linear .7s;

    &.in {
        transition: all linear .7s;
        opacity: 1;
    }
}

.shows-past {
    column-count: 3;
    font-size: 16px;

    @media (max-width: $desktop) {
        column-count: 2;
    }

    @media (max-width: $tablet) {
        @include column-centered;
    }

    .shows-past-list-item {
        line-height: 36px;
    }
}

.toggle-shows {
    font-size: 16px;
    cursor: pointer;
    margin-top: 0;
    text-align: center;
}

.card {
    background-color: transparent !important; //overrides Bootstrap
}

.card-body {
    padding-top: 0 !important; //overrides Bootstrap
}

.card-header {
    padding-bottom: 0 !important; //overrides Bootstrap
}