@import '../../../scss/variables';

.scroll-top-wrapper {
    position: fixed;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    text-align: center;
    z-index: 99;
    background-color: #666666;
    color: #eeeeee;
    line-height: 48px;
    right: 30px;
    bottom: 50px;
    width: 50px;
    height: 48px;
    border-radius: 10px;
    padding-top: 2px;
    transition: all 0.5s ease-in-out;

    @media (max-width: $phone) {
        right: 10px;
        bottom: 50px;
        border-radius: 8px;
    }
}

.scroll-top-wrapper:active,
.scroll-top-wrapper:hover {
    background-color: #888888;
}

.scroll-top-wrapper.show {
    visibility: visible;
    cursor: pointer;
    opacity: .7;
}

.scroll-top-icon {
    padding-right: 0;
    margin-top: 6px;
    cursor: pointer;
}
