@import '../../../scss/variables';

.video-container {
    background: url('/images/backgrounds/video_bg.jpg') no-repeat center center fixed;
    background-size: cover;
    min-height: 100% !important;

    @media (max-width: $tablet) {
        min-height: 50% !important;
    }
}

.youtube-container {
    position: relative;
    padding-top: 20px;
    margin: 0 auto;
    width: 600px;

    &:first-child {
        padding-top: 100px;
    }

    &:last-child {
        padding-bottom: 40px;
    }

    @media (max-width: $tablet) {
        width: 320px;
    }
}

.section-header-video {
    font-size: 30px;
    color: #fff;
    text-align: center;
    border: none;
    margin-bottom: 10px;
}

.youtube-video {
    z-index: 9;
    position: relative;
    height: 400px !important;

    @media (max-width: $tablet) {
        height: 240px !important;
    }

    iframe {
        width: 600px !important;
        height: 400px !important;

        @media (max-width: $tablet) {
            width: 320px !important;
            height: 240px !important;
        }
    }
}

.home-youtube-container {
    margin: auto 0;
    width: 100%;
    opacity: .98;
    position: fixed;
    height: 100%;

    > * {
        height: 100%;
    }

    .video-loader {
        z-index: -20;
    }
}

.home-youtube-video {
    iframe {
        position: absolute;
        width: 100% !important;
        height: 100% !important;
    }
}

.video-loader {
    color: $main-text-color;
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
}

