@import '../../../scss/variables';

.container {
    width: 90% !important;  // overrides Bootstrap
    max-width: 992px !important;  // overrides Bootstrap
    margin: 0 auto;

    @media (max-width: $desktop) {
        max-width: 768px;
    }
}

.fixed-bg {
    position: relative;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    z-index: 1;

    &:first-child {
        height: 100vh;
    }

    @media (max-width: $desktop-wide) {
        background-attachment: scroll;
    }

    @media (max-width: $tablet) {
        min-height: 60%;
    }

    @media (max-width: $phone) {
        max-height: 40%;
    }

    .quote {
        position: absolute;
        left: 50%;
        top: 50%;
        bottom: auto;
        right: auto;
        transform: translateX(-50%) translateY(-50%);
        width: 60%;
        max-width: 1170px;
        text-align: center;
        font-size: 48px;
        font-weight: 300;
        line-height: 52px;
        text-shadow: 2px 2px 8px rgba(0, 0, 0, 1);
        color: #fff;

        @media (max-width: $desktop-wide) {
            font-size: 36px;
        }

        @media (max-width: $tablet) {
            font-size: 30px;
            width: 80%;
            line-height: 42px;
        }

        .quote-author {
            font-size: 28px;
            display: block;

            @media (max-width: $tablet) {
                font-size: 22px;
                display: block;
            }
        }
    }
}

.fixed-bg {
    &.bg-quote1 {
        background-image: url('/images/backgrounds/home_bg1.jpg');
    }

    &.bg-quote2 {
        background-image: url('/images/backgrounds/home_bg2.jpg');
    }

    &.bg-quote3 {
        background-image: url('/images/backgrounds/home_bg3.jpg');
    }

    &.bg-quote4 {
        background-image: url('/images/backgrounds/home_bg4.jpg');
    }

    &.bg-quote5 {
        background-image: url('/images/backgrounds/home_bg5.jpg');
    }
}

.scrolling-bg {
    position: relative;
    min-height: 50%;
    padding: 70px 0 60px;
    font-size: 20px;
    line-height: 1.6;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
    z-index: 2;

    @media (max-width: $tablet) {
        padding: 60px 0;
        font-size: 20px;
    }

    .section-header {
        border-bottom: 1px #fff solid;
        font-size: 30px;
        padding-bottom: 10px;
        margin-bottom: 30px;
    }
}

.scrolling-bg {
    &.news-bg {
        background-image: url('/images/backgrounds/scroll_bg1.jpg');
        color: $main-text-color;
    }

    &.show-bg {
        background-image: url('/images/backgrounds/scroll_bg2.jpg');
        color: $main-text-color;
        min-height: 0;
    }

    &.band-bg {
        background-image: url('/images/backgrounds/scroll_bg3.jpg');
        color: $main-text-color;
    }

    &.booking-bg {
        background-image: url('/images/backgrounds/scroll_bg4.jpg');
        color: $main-text-color;
    }

    &.social-media-icons-bg {
        width: 100%;
        min-height: 0;
        height: 120px;
        background: #333;
        line-height: 0;
        padding-top: 18px;
    }
}
