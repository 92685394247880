@import '../../../../scss/variables';

.mobile-menu {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @media (max-width: $tablet-wide) {
        display: block;
    }

    &-link {
        height: auto;
        line-height: normal;
        background: transparent;
        border-top: none;
        font-size: 16px;
        text-transform: uppercase;
        display: block;
        color: #b3b3b1;
        padding: 15px 30px;
        border-bottom: 1px solid rgba(0,0,0,.1);
        border-top: 1px solid rgba(255,255,255,.1);
        text-decoration: none;

        @media (max-width: $desktop) {
            padding: 15px 20px 10px;
            font-size: 14px;
        }

        &:hover,
        &.active {
            text-decoration: none;
        }

        &:focus {
            text-decoration: none;
        }

        &-list {
            position: static;
            line-height: 50px;
            transform: none;
            width: 200px;
            height: 100%;
            background: #333332;
            font-size: .9em;
            font-weight: 700;
            box-shadow: inset -10px 0 6px -9px rgba(0,0,0,.7);
            overflow: auto;
        }

        &-list-item {
            display: block;
            transition: all .3s linear;

            &:hover,
            &.active {
                background: #fff;
                color: #333;
            }
        }
    }
}

.mobile-menu-button {
    float: right;
    display: none;
    margin-right: 5%;
    color: #8e7d7f;
    cursor: pointer;
    margin-top: 10px;
    background: transparent;
    border: none;
    transition: $link-hover-animation;

    &:hover {
        color: #fff;
    }

    @media (max-width: $tablet-wide) {
        display: block;
    }
}

.full-height {
    min-height: 100% !important;
}

.show-menu {
    display: block;
}

.mobile-scrollspy-hover:not(:first-child) a {
    background: #fff;
    color: #333;
}