@import '../../../scss/variables';

.band-container {
    text-align: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
}

.band-member-container {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 250px;
    min-width: auto;
    margin-top: 25px;

    @media (max-width: $tablet) {
        min-width: 250px;
    }
}

.band-member-image {
    border: 2px solid #fff;
    border-radius: 8px;
    filter: drop-shadow(5px 5px 5px #222);
}

.band-member-name {
    margin: 15px 0 0 5px;
    padding-bottom: 0;
    line-height: 22px;
}

.band-member-title {
    font-size: 16px;
    font-style: italic;
    margin-top: 5px;
    padding-top: 0;
    display: block;
    line-height: 20px;
}