@import '../../../scss/variables';

footer {
    width: 100%;
    height: 35px;
    background: rgba(0, 0, 0, 0.7);
    margin: 0;
    overflow: hidden;
    position: fixed;
    bottom: 0;
    left: 0;
    text-align: center;
    z-index: 9;
    box-shadow: 0 -4px 8px 0 rgba(0,0,0,0.75);
}