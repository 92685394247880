@import '../../../../scss/variables';

.social-media-footer {
    text-align: center;

    .social-media-footer-icon-container {
        display: inline !important;
        padding-right: 10px;
    }

    .social-media-footer-icon {
        padding: 0 30px;
        color: $link-hover-gray;

        @media (max-width: $tablet) {
            padding: 0 12px;
        }

        @media (max-width: $phone) {
            padding: 0 5px;
        }

        &:hover {
            color: #fff;
        }
    }
}