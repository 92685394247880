@import '../../../scss/variables';
@import '../../../scss/mixins';

.booking-info {
    font-size: 24px;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 30px;
    background: rgba(153, 153, 153, 0.3);
    box-shadow: $main-shadow;
    text-align: center;

    .booking-info-link {
        padding-right: 50px;

        @media (max-width: $phone) {
            display: block;
            padding-right: 0;
        }
    }

    @media (max-width: $tablet) {
        font-size: 22px;
    }
}

.song-list {
    @include column-centered;
    column-count: 3;

    @media (max-width: $tablet) {
        column-count: 2;
    }

    @media (max-width: $phone) {
        column-count: 1;
    }

    .song-list-item {
        line-height: 36px;
    }
}