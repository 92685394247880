@import '../../../../scss/variables';

.social-media-feeds {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    align-content: center;
    margin-top: 25px;

    @media (max-width: $desktop) {
        width: 650px;
        justify-content: center;
    }

    @media (max-width: $tablet) {
        width: auto;
    }
}

.news-social-media-icon {
    padding-right: 10px;
}

.social-media-no-feeds {
    margin-top: 50px;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 1);
    text-align: center;

    @media (max-width: $desktop) {
        margin-top: 30px;
    }

    .social-media-no-feeds-title {
        margin-top: 0;
        margin-bottom: 30px;
        display: block;
        padding: 0;
        font-size: 20px;
        text-align: center;
        line-height: 30px;

        @media (max-width: $desktop) {
            padding: 20px 20px 10px;
            font-size: 20px;
            margin-top: 0;
            margin-bottom: 30px;
            display: inline;
        }

        @media (max-width: $tablet) {
            padding: 20px 15px 10px;
        }

        @media (max-width: $phone) {
            display: block;
            padding: 10px 15px 0;
        }
    }
}

.social-media-feed-title {
    font-size: 24px;
    text-align: center;
    line-height: 30px;
    padding-bottom: 10px;

    @media (max-width: $tablet) {
        font-size: 20px;
        padding-bottom: 5px;
        padding-top: 20px;
    }
}

.twitter-feed-container {
    flex-grow: 1;
    flex-basis: 0;
    min-width: 320px;
    max-width: 320px !important; // overrides Bootstrap
    padding-left: 0 !important; // overrides Bootstrap
    padding-right: 30px !important; // overrides Bootstrap
    margin-bottom: 10px;
}

.twitter-timeline {
    max-height: 330px;
    overflow-y: auto;
}

.instagram-feed-container {
    flex-grow: 1;
    flex-basis: 0;
    min-width: 330px;
    max-width: 330px !important; // overrides Bootstrap
    padding-left: 0 !important; // overrides Bootstrap
    padding-right: 0 !important; // overrides Bootstrap
    height: 100%;
    margin-bottom: 20px;
}

.instagram-feed {
    border: 3px solid #fff;
    max-height: 330px;
    background: #000;
}

.instagram-link {
    position: absolute;
    width: 330px;
    height: 100%;
    top: 0;
    left: 0;
    display: inline-block;
    z-index: 8;

    @media (max-width: $tablet) {
        width: 100%;
    }
}

.instagram-feed .powered_by {
    display: none;
}
