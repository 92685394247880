@import '../../../scss/variables';

.news-container {
    background: url('/images/backgrounds/news_bg.jpg') no-repeat center center fixed;
    background-size: cover;
    min-height: 1000% !important;

    @media (max-width: $tablet) {
        min-height: 50% !important;
    }
}

.article-container {
    padding: 80px 0 100px;
    margin: 0 auto;
    width: 70%;
    max-width: 992px;
    color: $main-text-color;
    font-size: 20px;
}

.section-header {
    border-bottom: 1px #fff solid;
    font-size: 30px;
    padding-bottom: 10px;
    margin-bottom: 30px;
}

.news-article {
    border-bottom: 1px solid #fff;
    padding-bottom: 10px;

    &:last-child {
        border: none;
    }

    &-date {
        font-size: 30px;
        font-style: italic;
        margin-top: 25px;
    }

    &-title {
        font-size: 24px;
        font-style: italic;
        line-height: 30px;
        margin-top: 0;
    }
}
