@import '../../../scss/variables';

.headroom {
    transition: all 0.6s cubic-bezier(.87, -.5, .19, 1.44);
    z-index: 100 !important;

    &.headroom--unfixed {
        position: fixed !important;
    }

    &.headroom--unpinned.headroom--scrolled,
    &.headroom--pinned.headroom--scrolled {
        transition: all 0.6s cubic-bezier(.87, -.5, .19, 1.44) !important;
    }

    &-wrapper {
        height: 0 !important;
    }
}

.header {
    width: 100%;
    height: 70px;
    background: rgba(0, 0, 0, 0.7);
    box-shadow: $main-shadow;
    z-index: 10;

    .logo {
        float: left;
        margin: 9px 0 0 5%;
        font-family: abite, serif;
        font-size: 30px;
        color: #fff;
    }
}
