@import '../../../scss/variables';

.photos-container {
    background: url('/images/backgrounds/photos_bg.jpg') no-repeat center center fixed;
    background-size: cover;
    min-height: 100% !important;

    @media (max-width: $tablet) {
        min-height: 50% !important;
    }
}

.section-header-photos {
    font-size: 30px;
    color: #fff;
    text-align: center;
    border: none;
    margin-bottom: 10px;
    padding-top: 100px;
}

.react-photo-gallery--gallery {
    max-width: 70%;
    margin: 30px auto 0;
    padding: 0 0 50px 0;

    img {
        padding: 2px;
        border-radius: 4px;
        background-color: #fff;
        transition: all 0.3s linear;

        &:hover {
            opacity: 0.6;
        }
    }
}

.photos-loader {
    color: $main-text-color;
    z-index: -1;
    position: absolute;
    top: 50%;
    left: 50%;
}

.photo-button-group {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
    padding-top: 10px;
}

.select-photos,
.select-photos.show {
    margin: 0 10px 15px;

    button.dropdown-toggle {
        color: #fff;
        background: #0f0f0f;
        border-color: #000;
        width: 120px;
        font-size: 18px;
        transition: $link-hover-animation;

        &:hover,
        &:active,
        &:focus {
            background: #333 !important;
            border-color: #000 !important;
            color: #fff !important;
        }
    }

    .photo-button {
        transition: $link-hover-animation;

        &:hover {
            background: #ececec;
        }
    }
}
